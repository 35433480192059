/**
 * Cookie Notification
 * 
 * Author: Wouter Dolk
 */
 var cookie_accept = function(){
    var self = this;

    self.init = function(){
        if (!($.cookie('cookie-bar') == 'yes')) {
            $('.js-cookie-bar').css("display", "initial");
        }  
        $('.js-cookie-accept').click( function(e){
            e.preventDefault();
            $('.js-cookie-bar').remove();
            $.cookie('cookie-bar', 'yes', {expires: 365 });
        });
    }
}
