/**
 * Animated Titles
 * 
 * Author: Mannus van der Meer
 */
 var animated_text = function(){
    var self = this;

    self.init = function(){
        self.fadeInBlock();
        $(window).scroll(function(){
            self.fadeInBlock();
        });
    }
    self.fadeInBlock = function(){
        // for each fader
        $('.js-fader-offset').each(function(){
            // when viewpoint passes text
            if ( ( $(window).scrollTop() + $(window).height() ) > $(this).find('.js-fader').offset().top ){
                // add class fadeInUp
                $(this).find('.js-fader').addClass('fadeInUp');
                $(this).find('.js-fader').removeClass('invisible');
            }
        });
    }
}