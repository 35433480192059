/**
 * Gallery slider
 * 
 * Author: Arjan Oosthoek
 * Edited by: Dennis Bruinen, Mannus van der Meer
 */
 var gallery_slider = function(){
    var self = this;

    self.init = function(){
        var wrapper_slider = ( $('.js-gallery-wrapper').width() - 30 );
        var item_slider = 0;

        $('.js-slider-item').each(function(){
            item_slider = (item_slider + $('.js-slider-item').width()) ;
        });

        if (item_slider > wrapper_slider) {
            $('.js-grid-wrapper').addClass( 'js-slider' );
            $('.js-grid-wrapper').addClass( 'logo-slider-wrapper' );
        };

        // for each slider, start intervals
        $('.js-slider').each(function(){
            // get current slider element
            var this_el = this;
            // start interval
            var interval_id = setInterval( function(){ 
                // start scrolling
                self.animateSlider( $('.js-slider-item', this_el) ); 
            },15);

            // add interval_id as attribute
            $(this).attr('data-interval-id', interval_id);
        });

        $('.js-slider').mouseover( function(){
            // get the interval id of this slider
            var interval_id = $(this).attr('data-interval-id');
            // stop scrolling
            clearInterval( interval_id );

        }).mouseout( function(){
            var this_el = this;
            // start scrolling again
            interval_id = setInterval( function(){
                self.animateSlider( $('.js-slider-item', this_el) ); 
            },15);

            // add interval_id again as attribute
            $(this).attr('data-interval-id', interval_id);
        });          

    }

    self.animateSlider = function( $item ){
        // calculate the width of 1 item
        var item_width = $item.width();
        // add the margin-right to the width
        item_width += parseInt( $item.css('margin-right') );
        // get the first item
        var first = $item.first();
        // check the margin-left of the first item
        var margin_left = parseInt( first.css('margin-left') ) -1;
        // if the margin is smaller or equal to the width of one item, detach and append it
        if( margin_left <= ( item_width * -1 ) ){
            // detach the first item, append it at the end of the row and set the margin-left to 0
            var item = first.detach();
            $item.parent('.js-slider').append(item);
            item.css('margin-left', 0);
        } else {
            first.css('margin-left', margin_left);
        }
    }
}