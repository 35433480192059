/**
 * Detail Buttons
 * 
 * Author: Bart Alewijnse, Wouter Dolk
 * 
 */
var nav_buttons = function(){
    var self = this;

    self.init = function(){

        // The scroll function
        $(window).on('scroll', function() {
            // when the user reaches the middle of the page
            if( $(window).scrollTop() > $(document).height()/2 - $(window).height()/2 ) {
                // set variables for calculations
                var x = $(window).scrollTop() - ($(document).height()/2 - $(window).height()/2 );
                var pix = ($(window).height() - $('.overlay').height() - 230 ) / ($(document).height()/2 - $(window).height()/2 ) * x;
                // give the flaoting buttons a top with the calculated speed increase when scrolling
                $('.js-floating-nav').css({"top" : pix + $('.overlay').height()});
            }else{
                // if the buttons did not reach this point yet have the top on auto
                $('.js-floating-nav').css({"top" : "auto"});
            }
            // when reachingg the bottom of the page, show the buttons with the prev,next text
            if($(window).scrollTop() + $(window).height() == $(document).height()) {
                $('.js-nav-buttons').css({"visibility": "visible"});
                $('.js-floating-nav').addClass("hidden");
                $('.js-nav-text').addClass('fadeInUp');;
            }else{
                // if not show the floating buttons
                $('.js-nav-buttons').css({"visibility": "hidden"});
                $('.js-floating-nav').removeClass("hidden");
                $('.js-nav-text').removeClass('fadeInUp');
            }
        });
    }
}

