/**
* Sticky Menu
* 
* Author: Wouter Dolk
*/
var sticky_menu = function(){

    var self = this;

    self.init = function(){
        $(window).scroll(function() {
            if ($(this).scrollTop() > 1){  
                $('.js-main-menu-holder').addClass('sticky');
            } else {
                $('.js-main-menu-holder').removeClass('sticky');
            }
        });
    }
}
